<template>
  <v-card ref="container" max-width="1200" class="mx-auto mt-4">
    <v-row no-gutters v-if="$store.getters.getUserId === id_usuario">
      <v-spacer></v-spacer>
      <v-card-actions>
        <v-btn icon @click="showEdit">
          <v-icon>mdi-wrench</v-icon>
        </v-btn>
        <v-dialog fullscreen max-width="600" v-model="edit.shown" persistent>
          <v-card>
            <v-card-title>Editar perfil</v-card-title>
            <v-divider></v-divider>
            <v-form
              class="pa-4"
              ref="editForm"
              lazy-validation
              @submit.prevent="sendEdit"
            >
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="edit.newNombre"
                    name="newNombre"
                    filled
                    label="Nuevo nombre de usuario"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="col-md-6">
                  <v-text-field
                    filled
                    name="newPass"
                    v-model="edit.newPass"
                    type="password"
                    label="Nueva contraseña"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="col-md-6">
                  <v-text-field
                    filled
                    v-model="edit.newPass2"
                    name="newPass2"
                    type="password"
                    label="Repetir nueva contraseña"
                    :rules="[
                      edit.newPass === edit.newPass2 ||
                        'Las contraseñas no coinciden',
                    ]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-textarea
                    filled
                    no-resize
                    label="Biografía"
                    counter="1000"
                    :rules="[edit.rules.max]"
                    auto-grow
                    v-model="edit.newBio"
                  >
                  </v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-file-input
                    ref="inputImg"
                    accept=".png, .jpg, .jpeg"
                    name="newAvatar"
                    @change="previewImg"
                    label="Nueva imagen de perfil"
                    :rules="[edit.rules.img]"
                  ></v-file-input>
                </v-col>
              </v-row>
              <v-row
                v-show="edit.newImg != '' && edit.newImg != null"
                justify="center"
                no-gutters
              >
                <v-img
                  v-if="!cropping"
                  alt="nuevo_avatar"
                  max-width="300"
                  :src="edit.newImg"
                />
                <v-btn fab color="primary" @click="edit.newImg = ''">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-row>
              <v-dialog
                justify="center"
                max-width="700"
                max-height="700"
                v-model="showCropper"
                persistent
              >
                <v-card class="pa-4">
                  <cropper
                    class="cropper"
                    :src="newAvatar"
                    stencil-component="circle-stencil"
                    ref="inputImgcropper"
                  ></cropper>
                  <v-divider class="mt-4"></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" text @click.prevent="nocrop()"
                      >Cancelar</v-btn
                    >
                    <v-btn
                      color="primary"
                      text
                      @click.prevent="crop()"
                      :loading="cropping"
                      >Aceptar</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-divider></v-divider>
              <v-row no-gutters align="center">
                <v-spacer></v-spacer>
                <v-card-actions>
                  <v-btn color="error" type="reset" @click="editReset()"
                    >Cancelar</v-btn
                  >
                  <v-btn color="primary" type="submit">Actualizar</v-btn>
                </v-card-actions>
              </v-row>
            </v-form>
          </v-card>
        </v-dialog>
      </v-card-actions>
    </v-row>
    <v-row v-else><v-card-actions></v-card-actions></v-row>
    <v-row no-gutters class="pa-4 text--center" align="center" justify="center">
      <v-col cols="auto" class="px-4">
        <v-avatar size="200">
          <v-img contain :src="avatar" alt="nuevo_avatar">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-overlay absolute opacity="0.1">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-overlay>
              </v-row>
            </template>
          </v-img>
        </v-avatar>
      </v-col>
      <v-col class="px-4">
        <v-card-title class="d-inline-block">{{ usuario.nombre }}</v-card-title>
        <v-card-subtitle v-if="$store.getters.getUserId === id_usuario"
          >Te uniste el {{ usuario.fregistro }}</v-card-subtitle
        >
        <v-card-subtitle v-else
          >Se unió el {{ usuario.fnacimiento }}</v-card-subtitle
        >
        <v-btn
          v-if="$store.getters.getUserId === id_usuario"
          color="primary"
          class="ma-4"
          outlined
          small
          @click="logout()"
          >Cerrar sesión</v-btn
        >
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-card-subtitle class="text-h6">Biografía</v-card-subtitle>
      <v-card-text v-if="usuario.bio != ''">{{ usuario.bio }}</v-card-text>
      <v-card-text v-else>Aún no tienes biografía</v-card-text>
    </v-row>
    <v-divider></v-divider>
    <v-row no-gutters>
      <v-tabs v-model="tab" show-arrows icons-and-text grow>
        <v-tab key="0">
          <span v-show="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs"
            >Favoritos</span
          ><v-icon>mdi-heart</v-icon>
        </v-tab>
        <v-tab key="1">
          <span v-show="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs"
            >Siguiendo</span
          ><v-icon>mdi-bookmark</v-icon>
        </v-tab>
        <v-tab key="2" v-if="$store.getters.getUserId === id_usuario">
          <span v-show="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs"
            >Recientes</span
          ><v-icon>mdi-clock</v-icon>
        </v-tab>
        <v-tab key="3">
          <span v-show="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs"
            >Comentarios</span
          ><v-icon>mdi-comment</v-icon>
        </v-tab>
      </v-tabs>
      <v-container fluid>
        <v-tabs-items touchless v-model="tab">
          <v-tab-item key="0" v-intersect.once="getFavoritos">
            <v-row
              no-gutters
              v-if="favoritos.length > 0"
              class="d-flex flex-nowrap overflow-x-auto"
            >
              <Lugar
                class="grid"
                v-for="lugar in favoritos"
                :key="Number(lugar.id)"
                :id="Number(lugar.id_old)"
                :nombre="lugar.nombre"
                :cp="lugar.cp"
                :img_src="lugar.imagen_defecto"
              />
            </v-row>
            <v-row no-gutters v-else>
              <p class="ma-4">Este usuario aún no tiene favoritos</p>
            </v-row>
          </v-tab-item>
          <v-tab-item key="1" v-intersect.once="getSiguiendo">
            <v-row
              no-gutters
              v-if="siguiendo.length > 0"
              class="d-flex flex-nowrap overflow-x-auto"
            >
              <Lugar
                class="grid"
                v-for="lugar in siguiendo"
                :key="Number(lugar.id)"
                :id="Number(lugar.id_old)"
                :nombre="lugar.nombre"
                :cp="lugar.cp"
                :img_src="lugar.imagen_defecto"
              />
            </v-row>
            <v-row no-gutters v-else>
              <p class="ma-4">Este usuario aún no sigue lugares</p>
            </v-row>
          </v-tab-item>
          <v-tab-item key="2" v-intersect.once="getHistorial">
            <v-row
              no-gutters
              v-if="historial.length > 0"
              class="d-flex flex-nowrap overflow-x-auto"
            >
              <Lugar
                class="grid"
                v-for="lugar in historial"
                :key="Number(lugar.id)"
                :id="Number(lugar.id_old)"
                :nombre="lugar.nombre"
                :cp="lugar.cp"
                :img_src="lugar.imagen_defecto"
              />
            </v-row>
            <v-row no-gutters v-else>
              <p class="ma-4">Parece que tu historial está vacío</p>
            </v-row>
          </v-tab-item>
          <v-tab-item key="3" v-intersect.once="getComentarios">
            <v-row v-if="comentarios.length > 0">
              <Comment
                v-for="comment in comentarios"
                :key="Number(comment.id_comentario)"
                :id_comentario="Number(comment.id_comentario)"
                :id_lugar="Number(comment.id_old)"
                :comment="comment.comentario"
                :id_usuario="Number(comment.id_usuario)"
                :img_src="comment.direccion_imagen || null"
                :nombre="comment.nombre"
                :lnombre="comment.lnombre"
                :fecha="comment.escrito_en"
                :avatar_src="comment.avatar"
                :isReply="comment.id_parent != '0' ? true : false"
                no-like
                no-flag
                no-reply-controls
                no-link
                :controls="
                  comment.id_usuario == $store.getters.getUserId ? true : false
                "
              />
            </v-row>
            <v-row v-else no-gutters>
              <p class="ma-4">Este usuario aún no ha comentado</p>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-container>
    </v-row>
  </v-card>
</template>

<script>
import Lugar from "@/components/Lugar.vue";
import Comment from "@/components/Comment.vue";

import Hermite_class from "hermite-resize";
import axios from "redaxios";

import { max } from "@/utils/validationRules.js";

export default {
  components: {
    Lugar,
    Comment,
  },
  data() {
    return {
      id_usuario:
        this.$route.params.id_usuario || this.$store.getters.getUserId,
      avatar: "",
      newAvatar: "",
      showCropper: false,
      cropping: false,
      usuario: {},
      edit: {
        shown: false,
        newNombre: "",
        newPass: "",
        newPass2: "",
        newImg: "",
        newBio: "",
        rules: {
          img: (v) =>
            !v ||
            v.size < 5000000 ||
            "El tamaño de la imágen debe ser inferior a los 5MB",
          max: (v) => max(v, 1000),
        },
        validation: false,
      },
      tab: null,
      favoritos: [],
      siguiendo: [],
      historial: [],
      comentarios: [],
    };
  },
  methods: {
    nocrop() {
      this.edit.newImg = "";
      this.showCropper = false;
    },
    crop() {
      this.cropping = true;
      this.showCropper = false;
      var HERMITE = new Hermite_class();
      const { canvas } = this.$refs.inputImgcropper.getResult();
      let resizeCanvas = document.createElement("canvas");
      resizeCanvas.height = canvas.height;
      resizeCanvas.width = canvas.width;
      let resizeCtx = resizeCanvas.getContext("2d");
      resizeCtx.drawImage(canvas, 0, 0);
      resizeCtx.globalCompositeOperation = "destination-in";
      resizeCtx.beginPath();
      resizeCtx.arc(
        resizeCanvas.width / 2,
        resizeCanvas.height / 2,
        resizeCanvas.height / 2,
        0,
        Math.PI * 2
      );
      resizeCtx.closePath();
      resizeCtx.fill();
      HERMITE.resample_single(resizeCanvas, 350, 350, true);
      this.edit.newImg = resizeCanvas.toDataURL("image/png");
      this.cropping = false;
    },
    showEdit() {
      this.$nextTick(() => {
        this.edit.shown = !this.edit.shown;
      });
    },
    editReset() {
      this.$refs.inputImg.resetValidation();
      this.edit.newPass = "";
      this.edit.newPass2 = "";
      this.edit.newImg = "";
      this.edit.shown = false;
    },
    async previewImg(e) {
      if (e && e.size > 5000000) return;

      try {
        this.newAvatar = URL.createObjectURL(e);
        this.showCropper = true;
      } catch {
        this.newAvatar = "";
      }
    },
    sendEdit() {
      if (
        this.$refs.editForm.validate() &&
        (this.edit.newNombre != "" ||
          this.edit.newPass != "" ||
          this.edit.newImg != "" ||
          this.edit.newBio != "")
      ) {
        let fotoFile;
        try {
          const byteCharacters = atob(this.edit.newImg.split(",")[1]);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          fotoFile = new Blob([byteArray], { type: "image/png" });
        } catch (err) {
          fotoFile = null;
        }
        if (this.edit.newNombre == "") {
          this.edit.newNombre = this.usuario.nombre;
        }
        this.$root.confirm
          .open({
            nuevoNombre: this.edit.newNombre,
            nuevaClave: this.edit.newPass,
            nuevaFoto: fotoFile,
            nuevaBio: this.edit.newBio,
          })
          .then(() => {
            this.getUserInfo();
            this.editReset();
            this.$root.$emit("snack", "Los datos han sido actualizados");
          })
          .catch(() => {
            this.$root.$emit("snack", "No se han podido actualizar los datos");
          });
      } else {
        this.$root.$emit("snack", "Tienes que rellenar algún campo");
      }
    },
    getUserInfo() {
      axios({
        url: `${process.env.VUE_APP_API_URL}/usuario.php`,
        method: "GET",
        params: {
          id_usuario: this.id_usuario,
        },
      })
        .then((res) => {
          this.usuario = res.data;
          if (res.data == false)
            throw new Error(
              "No se ha encontrado este perfil en la base de datos"
            );
          this.edit.newNombre = this.usuario.nombre;
          this.edit.newBio = this.usuario.bio;
          this.avatar = `/static/avatar/${this.usuario.avatar}`;
        })
        .catch((err) => {
          console.error(err);
          this.$router.push({ name: "Perfil" }).catch(() => {
            this.$router.push({ name: "Login" });
          });
          this.$root.$emit("snack", "Perfil no encontrado.");
        });
    },
    getFavoritos() {
      axios({
        url: `${process.env.VUE_APP_API_URL}/favoritos.php`,
        method: "GET",
        params: {
          id_usuario: this.id_usuario,
        },
      }).then((res) => {
        this.favoritos = res.data;
      });
    },
    getSiguiendo() {
      axios({
        url: `${process.env.VUE_APP_API_URL}/siguiendo.php`,
        method: "GET",
        params: {
          id_usuario: this.id_usuario,
        },
      }).then((res) => {
        this.siguiendo = res.data;
      });
    },
    getHistorial() {
      let historial = sessionStorage
        .getItem("lugaresRecientes")
        .split(",")
        .map((item) => Number(item));
      axios({
        method: "POST",
        url: `${process.env.VUE_APP_API_URL}/historial.php`,
        data: {
          token: this.$store.getters.getToken,
          historial: historial,
        },
      }).then((res) => {
        let result = [];

        historial.forEach(function (key) {
          var found = false;
          res.data = res.data.filter(function (item) {
            if (!found && Number(item.id_old) == key) {
              result.push(item);
              found = true;
              return false;
            } else return true;
          });
        });

        this.historial = result;
      });
    },
    getComentarios() {
      axios({
        url: `${process.env.VUE_APP_API_URL}/comentarios.php`,
        method: "GET",
        params: {
          id_usuario: this.id_usuario,
        },
      }).then((res) => {
        this.comentarios = res.data;
      });
    },
    logout() {
      if (confirm(`¿Estás seguro de que quieres cerrar sesión?`)) {
        this.$store.dispatch("logout").then(() => {
          this.$router.push({ name: "Home" });
          this.$root.$emit("snack", "Se ha cerrado la sesión");
        });
      }
    },
  },
  mounted() {
    this.getUserInfo();
  },
};
</script>